import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

// WARNING: we get "Cannot create styled-component for component:" in production if this is moved
// after the components include.
import { useTranslation } from "react-i18next";
import urlPatterns from "../../urls";
import { isWebpSupported, getCountrySettings } from "../../helpers/tools";
import JsonLdLiquorStore from "../../components/atoms/JsonLdLiquorStore";

import { Container } from "../../components/atoms/Container";
import { AlignCenter } from "../../components/atoms/Aligned";
import { Section, SectionBlue } from "../../components/atoms/Section";
import ColSpacer from "../../components/atoms/ColSpacer";
import Title from "../../components/atoms/Title";
import Image from "../../components/atoms/Image";
import Button from "../../components/atoms/Button";
import ButtonLink from "../../components/atoms/ButtonLink";
import PageMeta from "../../components/molecules/PageMeta";
import Gallery from "../../components/molecules/Gallery";
import ReviewsWidget from "../../components/molecules/ReviewsWidget";
import AppList from "../../components/atoms/AppList";
import SellingPoints from "../../components/molecules/SellingPoints";

import { BREAKPOINT, colors, fonts, header } from "../../styles/variables";
import breakpoints from "../../styles/breakpoints";
import fineTuneImage1 from "../../assets/images/elements/fine-tune-1.svg";
import fineTuneImage2 from "../../assets/images/elements/fine-tune-2.svg";
import fineTuneImage3 from "../../assets/images/elements/fine-tune-3.svg";
import floatedImageCouple from "../../assets/images/temp/how-fine-tune-floated.png";
import floatedImageCoupleAlt from "../../assets/images/temp/how-fine-tune-floated.webp";
import homeWineImage from "../../assets/images/general/good-pair-days-food-and-wine-pairings.jpg";
import homeWineImageAlt from "../../assets/images/general/good-pair-days-food-and-wine-pairings.webp";
import homeCupImage from "../../assets/images/temp/home_glass.png";
import homeCupImageAlt from "../../assets/images/temp/home_glass.webp";
import homeImageWineSubscription from "../../assets/images/temp/good-pair-days-wine-subscription.jpg";
import homeImageWineSubscriptionAlt from "../../assets/images/temp/good-pair-days-wine-subscription.webp";
import homeImageWineSubscriptionMobile from "../../assets/images/temp/good-pair-days-wine-subscription-mobile.png";
import homeImageWineSubscriptionMobileAlt from "../../assets/images/temp/good-pair-days-wine-subscription-mobile.webp";
import homeImageWinePairing from "../../assets/images/general/good-pair-days-wine-pairing.jpg";
import homeImageWinePairingAlt from "../../assets/images/general/good-pair-days-wine-pairing.webp";
import homeImageWinePairingTablet from "../../assets/images/general/good-pair-days-wine-pairing-tablet.jpg";
import homeImageWinePairingTabletAlt from "../../assets/images/general/good-pair-days-wine-pairing-tablet.webp";
import homeImageCards from "../../assets/images/general/good-pair-days-tastebuds-cards.png";
import homeImageCardsAlt from "../../assets/images/general/good-pair-days-tastebuds-cards.webp";
import homeImageCardsTablet from "../../assets/images/general/good-pair-days-tastebuds-cards-tablet.png";
import homeImageCardsTabletAlt from "../../assets/images/general/good-pair-days-tastebuds-cards-tablet.webp";
import homeImageCardsMobile from "../../assets/images/general/good-pair-days-tastebuds-cards-mobile.png";
import homeImageCardsMobileAlt from "../../assets/images/general/good-pair-days-tastebuds-cards-mobile.webp";
import heroBgDesktop from "../../assets/images/hero/good-pair-days-wine-subscription-hero-desktop.jpg";
import heroBgDesktopAlt from "../../assets/images/hero/good-pair-days-wine-subscription-hero-desktop.webp";
import heroBgMobile from "../../assets/images/hero/good-pair-days-wine-subscription-hero-mobile.jpg";
import heroBgMobileAlt from "../../assets/images/hero/good-pair-days-wine-subscription-hero-mobile.webp";
import homeNoPinotNoPlanet from "../../assets/images/general/good-pair-days-no-pinot-no-planet.png";
import { I18N_NAMESPACES } from "../../helpers/constants";
import HomePageBrandGallery from "../../components/organisms/HomePageBrandGallery";
import ScrollingAppImages from "../../components/organisms/ScrollingAppImages";

const Home = () => {
  const { t } = useTranslation([I18N_NAMESPACES.COMMON, I18N_NAMESPACES.HOME]);
  const textGalleryTitles = t("home:textGalleryTitles", { returnObjects: true }) || [];
  const { IS_COUNTRY_UK } = getCountrySettings();

  return (
    <>
      <PageMeta
        title={t("common:SEO.defaultMetaTitle")}
        canonicalUrl={process.env.REACT_APP_BASE_URL + urlPatterns.HOME}
      />
      <JsonLdLiquorStore />
      <Hero isWebpSupported={isWebpSupported()}>
        <Container>
          <Row>
            <Col lg={7} lgOffset={5}>
              <HeroTitleHolder>
                <HeroTitle>
                  Discover wines,
                  <br /> paired to your tastes,
                  <br /> delivered to your door
                </HeroTitle>
                <ButtonLink id="homeHeroCtaButton" to={urlPatterns.QUIZ} lg long fullMobile whiteBgOnHover>
                  DISCOVER YOUR WINES
                </ButtonLink>
              </HeroTitleHolder>
            </Col>
          </Row>
        </Container>
      </Hero>
      <HomeSectionsWrapper>
        <SectionPress>
          <Container>
            <PressQuotesContainer>
              <Gallery>
                {typeof textGalleryTitles === "object" &&
                  textGalleryTitles.map((title) => <TextGalleryTitle key={title}>{title}</TextGalleryTitle>)}
              </Gallery>
            </PressQuotesContainer>
            <HomePageBrandGallery />
          </Container>
        </SectionPress>
        <SectionHow>
          <Container>
            <Title tag={2} uppercase center>
              How it works
            </Title>
            <FineTuneHolder>
              <FineTune>
                <FineTuneBody>
                  <Title tag={3} fontSecondary mbSmall>
                    Discover your taste profile.
                  </Title>
                  <p>
                    <Link id="homeHowCtaLink" to={urlPatterns.QUIZ}>
                      Take our fun palate quiz
                    </Link>
                    {" to uncover your unique taste profile. We'll recommend the best wines, "}
                    {"sourced from all over the world, to suit your palate, "}
                    {"preferences and budget!"}
                  </p>
                </FineTuneBody>

                <FineTuneImageHolder>
                  <LazyLoad once offset={500} height={200}>
                    <FineTuneImage alt="1- Discover Your Taste Profile" src={fineTuneImage1} full maxFull />
                  </LazyLoad>
                </FineTuneImageHolder>
              </FineTune>

              <FineTune>
                <FineTuneBody>
                  <Title tag={3} fontSecondary mbSmall>
                    Start your wine journey.
                  </Title>
                  {IS_COUNTRY_UK ? (
                    <p>
                      Shipping UK wide with grape and recipe guides included. It’s free to sign up, only pay
                      for what you drink, with bottles starting at £9. Cancel, pause or change your box size
                      whenever you like.
                    </p>
                  ) : (
                    <p>
                      Shipping Australia wide with grape and recipe guides included. It’s free to sign up,
                      only pay for what your drink, with bottles starting at $14. Cancel, pause or change your
                      box size whenever you like.
                    </p>
                  )}
                </FineTuneBody>
                <FineTuneImageHolder>
                  <LazyLoad once offset={500} height={200}>
                    <FineTuneImage alt="2- We Pair Wine To Your Tastes" src={fineTuneImage2} full maxFull />
                  </LazyLoad>
                </FineTuneImageHolder>
              </FineTune>

              <FineTune>
                <FineTuneBody>
                  <Title tag={3} fontSecondary mbSmall>
                    Drink. Rate. Learn.
                  </Title>
                  <p>
                    Enjoy your selection. Rate your wines for better recommendations from our world class wine
                    team. Level up by taking our free wine course and collect points & rewards for exploring
                    new wines!
                  </p>
                  <ButtonLink id="homeHowCtaButton" to={urlPatterns.QUIZ} lg fullMobile whiteBgOnHover>
                    DISCOVER YOUR WINES
                  </ButtonLink>
                </FineTuneBody>

                <FineTuneImageHolder>
                  <LazyLoad once offset={500} height={200}>
                    <FineTuneImage alt="3- Drink. Rate. Learn." src={fineTuneImage3} full maxFull />
                  </LazyLoad>
                </FineTuneImageHolder>
              </FineTune>
            </FineTuneHolder>
          </Container>
        </SectionHow>

        <StyledSectionOverflow>
          <ReviewsWidget showImage />
        </StyledSectionOverflow>

        <SectionScroll>
          <SectionScrollContainer>
            <SellingPoints />
          </SectionScrollContainer>
        </SectionScroll>

        <CallToActionButtonSection>
          <Container>
            <ButtonLink
              id="homeSellingPointsCtaButton"
              to={urlPatterns.QUIZ}
              lg
              long
              fullMobile
              whiteBgOnHover
            >
              DISCOVER YOUR WINES
            </ButtonLink>
          </Container>
        </CallToActionButtonSection>

        <SectionSommelier>
          <LazyLoad once offset={500} height={300}>
            <FloatedImageCouple webpSrc={floatedImageCoupleAlt} />
          </LazyLoad>
          <LazyLoad once offset={500} height={700}>
            <FloatedImageSommelier webpSrc={homeWineImageAlt} />
          </LazyLoad>
          <LazyLoad once offset={500} height={700}>
            <FloatedImageCupSommelier webpSrc={homeCupImageAlt} />
          </LazyLoad>
          <ContainerSommelier>
            <TitleSommelier>A wine journey tailored to your taste buds</TitleSommelier>
          </ContainerSommelier>
        </SectionSommelier>

        <SectionPreview>
          <SectionPreviewLeft>
            <SectionPreviewLeftTop>
              <Title tag={3} fontSecondary mbMd>
                Download our App to keep track and interact with your monthly wine adventure.
              </Title>
              <AppList />
            </SectionPreviewLeftTop>
            <ScrollingAppImages />
          </SectionPreviewLeft>

          <SectionPreviewRight>
            <LazyLoad once offset={500} height={800}>
              <ZIndexImageDesktop
                src={homeImageWineSubscription}
                alt="Couple enjoying Good Pair Days"
                webpSrc={homeImageWineSubscriptionAlt}
                full
              />
            </LazyLoad>
            <LazyLoad once offset={500} height={800}>
              <ZIndexImageMobile
                src={homeImageWineSubscriptionMobile}
                alt="Couple enjoying Good Pair Days"
                webpSrc={homeImageWineSubscriptionMobileAlt}
                full
              />
            </LazyLoad>
          </SectionPreviewRight>
        </SectionPreview>

        <SectionNoPinot>
          <TextNoPinotContainer>
            <InnerNoPinotContainer>
              <TitleNoPinot>
                {t("home:noPlanet")}
                <br />
                {t("home:noPinot")}
              </TitleNoPinot>
              <p>{t("home:weCare")}</p>
              <p>{t("home:ourDonations")}</p>
              <a href="https://blog.goodpairdays.com/no-planet-no-pinot-2">
                <CtaButtonNoPinot id="homeNoPinotCtaButton" outline lg>
                  Learn More
                </CtaButtonNoPinot>
              </a>
            </InnerNoPinotContainer>
          </TextNoPinotContainer>
          <ImageNoPinotContainer>
            <LazyLoad once offset={500} height={260}>
              <ImageNoPinot alt="No Planet No Pinot" src={homeNoPinotNoPlanet} />
            </LazyLoad>
          </ImageNoPinotContainer>
        </SectionNoPinot>

        <SectionTastebuds>
          <LazyLoad once offset={500} height={500}>
            <ImageDesktopTastebuds
              alt="Good Pair Days with wine and food pairings"
              src={homeImageWinePairing}
              webpSrc={homeImageWinePairingAlt}
              full
            />
          </LazyLoad>

          <LazyLoad once offset={500} height={500}>
            <ImageTabletTastebuds
              alt="Good Pair Days with wine and food pairings"
              src={homeImageWinePairingTablet}
              webpSrc={homeImageWinePairingTabletAlt}
              full
            />
          </LazyLoad>

          <LazyLoad once offset={500} height={500}>
            <ImageMobileTastebuds
              alt="Good Pair Days with wine and food pairings"
              src={homeImageWinePairing}
              webpSrc={homeImageWinePairingAlt}
              full
            />
          </LazyLoad>

          <SectionTastebudsInner>
            <SectionTastebudsContent>
              <Title tag={2} uppercase>
                Grape Type And Recipe Guides With Every Bottle
              </Title>
            </SectionTastebudsContent>
          </SectionTastebudsInner>

          <LazyLoad once offset={500} height={500}>
            <ImageDesktopCards
              alt="Good Pair Days cards"
              src={homeImageCards}
              webpSrc={homeImageCardsAlt}
              full
            />
          </LazyLoad>

          <LazyLoad once offset={500} height={500}>
            <ImageTabletCards
              alt="Good Pair Days cards"
              src={homeImageCardsTablet}
              webpSrc={homeImageCardsTabletAlt}
              full
            />
          </LazyLoad>

          <ImageMobileCardsContainer>
            <LazyLoad once offset={500} height={500}>
              <ImageMobileCards
                alt="Good Pair Days cards"
                src={homeImageCardsMobile}
                webpSrc={homeImageCardsMobileAlt}
                full
              />
            </LazyLoad>
          </ImageMobileCardsContainer>
        </SectionTastebuds>

        <SectionWineAdventure>
          <Container>
            <Row middle="md">
              <Col md={7} lg={8}>
                <Title tag={2} fontSecondary>
                  Start your personalised wine adventure today
                </Title>
              </Col>

              <Col md={5} lg={4}>
                <ColSpacer sm />
                <ButtonLink id="homeWineAdventureCtaButton" to={urlPatterns.QUIZ} lg long fullMobile>
                  DISCOVER YOUR WINES
                </ButtonLink>
              </Col>
            </Row>
          </Container>
        </SectionWineAdventure>
      </HomeSectionsWrapper>
    </>
  );
};

const HomeSectionsWrapper = styled.div`
  overflow-x: hidden;
`;

const SectionScroll = styled(Section)`
  ${breakpoints.mdUp} {
    padding: 110px 0 0 0;
  }
  ${breakpoints.smDown} {
    padding: 80px 0 0;
  }
`;

const SectionScrollContainer = styled(Container)`
  ${breakpoints.lgUp} {
    max-width: 1450px;
  }
  ${breakpoints.smUp} {
    margin-bottom: -50px;
  }
`;

const CallToActionButtonSection = styled(Section)`
  ${breakpoints.mdDown} {
    padding: 30px 0 80px;
  }
  ${breakpoints.smDown} {
    padding-bottom: 50px;
  }
  a {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const SectionTastebuds = styled(Section)`
  padding: 0 !important;
  margin-bottom: 203px;
  ${breakpoints.xlUp} {
    max-width: ${BREAKPOINT.XL}px;
    margin-left: auto;
    margin-right: auto;
  }
  ${breakpoints.lgDown} {
    margin-top: 20px;
    margin-bottom: 133px;
  }
  ${breakpoints.smDown} {
    margin-top: 100px;
    margin-bottom: 0;
  }
`;

const SectionTastebudsInner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  ${breakpoints.lgUp} {
    left: 470px;
    top: 0;
    padding: 45px 45px;
  }
  ${breakpoints.lgDown} {
    top 45px;
    left: 185px;
  }
  ${breakpoints.mdDown} {
    top 57px;
    left: 164px;
  }
  ${breakpoints.smDown} {
    top -110px;
    left: 20px;
    padding: 40px 15px;
  }
`;

const SectionTastebudsContent = styled.div`
  max-width: 433px;
  h2 {
    letter-spacing: -0.24px;
    font-family: ${fonts.fontInterstateBlackCompressed};
  }
  ${breakpoints.lgUp} {
    h2 {
      font-size: 115px;
      line-height: 90px;
    }
  }
  ${breakpoints.lgDown} {
    h2 {
      font-size: 90px;
      line-height: 75px;
    }
  }
  ${breakpoints.mdDown} {
    max-width: 289px;
    h2 {
      font-size: 60px;
      line-height: 50px;
    }
  }
  ${breakpoints.smDown} {
    max-width: 330px;
    h2 {
      font-size: 60px;
      line-height: 48px;
      letter-spacing: -0.147692px;
    }
  }
`;

const ImageDesktop = styled(Image)`
  ${breakpoints.xsDown} {
    display: none;
  }
`;

const ImageDesktopTastebuds = styled(ImageDesktop)`
  height: 855px;
  width: auto;
  ${breakpoints.lgDown} {
    display: none;
  }
`;

const ImageTabletTastebuds = styled(Image)`
  height: auto;
  ${breakpoints.lgUp} {
    display: none;
  }
  ${breakpoints.lgDown} {
    width: 388px;
  }
  ${breakpoints.mdDown} {
    width: 267px;
  }
  ${breakpoints.smDown} {
    display: none;
  }
`;

const ImageMobileTastebuds = styled(Image)`
  width: 264px;
  height: auto;
  ${breakpoints.smUp} {
    display: none;
  }
`;

const ImageDesktopCards = styled(ImageDesktop)`
  z-index: -1;
  position: absolute;
  top: -30px;
  right: -5px;
  width: 850px;
  height: auto;
  @media (max-width: 1580px) {
    right: auto;
  }
  ${breakpoints.lgDown} {
    display: none;
  }
`;

const ImageTabletCards = styled(Image)`
  z-index: -1;
  position: absolute;
  ${breakpoints.lgDown} {
    top: 165px;
    right: -30px;
    width: 606px;
    height: auto;
  }
  ${breakpoints.mdDown} {
    top: 120px;
    right: -5px;
    width: 446px;
    height: auto;
  }
  ${breakpoints.lgUp} {
    display: none;
  }
  ${breakpoints.smDown} {
    display: none;
  }
`;

const ImageMobileCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${breakpoints.smUp} {
    display: none;
  }
`;

const ImageMobileCards = styled(Image)`
  margin-top: 30px;
  z-index: -1;
  min-width: 374px;
  max-width: 500px;
  ${breakpoints.smUp} {
    display: none;
  }
`;

const ImageMobile = styled(Image)`
  ${breakpoints.xsUp} {
    display: none;
  }
`;

const ZIndexImageDesktop = styled(ImageDesktop)`
  position: relative;
  z-index: 100;
  object-fit: cover;
  height: 100%;
`;

const ZIndexImageMobile = styled(ImageMobile)`
  position: relative;
  z-index: 100;
  object-fit: cover;
`;

const SectionNoPinot = styled(Section)`
  display: flex;
  flex-wrap: wrap;
  padding: 0 !important;
  min-height: 612px;
  background-color: ${colors.successThin};
`;

const TextNoPinotContainer = styled.div`
  p {
    max-width: 530px;
    font-size: 18px;
    line-height: 28px;
  }
  ${breakpoints.mdDown} {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 24px;
    padding: 32px 24px 0 24px !important;
  }
  ${breakpoints.mdUp} {
    width: 60%;
    padding-top: 96px;
  }
  ${breakpoints.lgUp} {
    width: 50%;
    padding-left: 210px;
  }
  @media (max-width: 1355px) {
    padding-left: 100px;
  }
`;

const InnerNoPinotContainer = styled.div`
  ${breakpoints.mdDown} {
    width: auto;
  }
  a {
    :hover {
      color: ${colors.white};
    }
  }
`;

const TitleNoPinot = styled.h2`
  margin-bottom: 32px;
  font-size: 50px;
  line-height: 46px;
  font-family: ${fonts.fontInterstateUltraBlack};
  text-transform: uppercase;
  ${breakpoints.xsDown} {
    font-size: 45px;
  }
`;

const CtaButtonNoPinot = styled(Button)`
  background-color: ${colors.successThin};
  width: 203px;
  margin-top: 20px;
  ${breakpoints.smDown} {
    width: 100%;
  }
`;

const ImageNoPinotContainer = styled.div`
  display: flex;
  align-items: flex-start;
  ${breakpoints.mdDown} {
    width: 100%;
    align-self: flex-end;
    justify-content: center;
  }
  ${breakpoints.mdUp} {
    width: 40%;
    align-self: flex-end;
  }
  ${breakpoints.lgUp} {
    width: 50%;
  }
`;

const ImageNoPinot = styled(Image)`
  width: 655px;
  ${breakpoints.lgUp} {
    margin-right: 45px;
  }
  ${breakpoints.mdDown} {
    width: 302px;
  }
`;

const SectionWineAdventure = styled(Section)`
  background: ${colors.peach};
`;

const TextGalleryTitle = styled.h2`
  font-family: ${fonts.fontInterstateBlackCompressed};
  font-size: 45px;
  line-height: 39px;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  ${breakpoints.lgDown} {
    font-size: 40px;
  }
  ${breakpoints.smDown} {
    font-size: 30px;
    line-height: 27px;
  }
`;

const SectionSommelier = styled(Section)`
  position: relative;
  margin-top: 250px;
  padding: 100px 0 360px 0;
  ${breakpoints.xlUp} {
    max-width: ${BREAKPOINT.XL}px;
    margin-left: auto;
    margin-right: auto;
  }
  ${breakpoints.smDown} {
    padding: 75px 0 500px 0;
  }
`;

const SectionPreview = styled.section`
  background-color: ${colors.coral};
  ${breakpoints.mdUp} {
    display: flex;
    flex-wrap: wrap;
  }
`;

const SectionPreviewItem = styled.div`
  display: flex;
  ${breakpoints.mdUp} {
    width: 40%;
  }
`;

const SectionPreviewLeft = styled(SectionPreviewItem)`
  ${breakpoints.mdUp} {
    order: 10;
    width: 60%;
  }
  background-color: ${colors.coral};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 55px 15px 60px 15px;
  ${breakpoints.smDown} {
    padding: 120px 15px 30px 15px;
    .rwt__tabs {
      max-width: 100%;
    }
  }
  ${breakpoints.xsDown} {
    padding-top: 58px;
  }
`;

const SectionPreviewLeftTop = styled.div`
  h3 {
    font-size: 45px;
    line-height: 46px;
    ${breakpoints.smDown} {
      font-size: 30px;
      line-height: 27px;
    }
  }
  ${breakpoints.smUp} {
    max-width: 609px;
  }
  position: relative;
  z-index: 20;
`;

const SectionPreviewRight = styled(SectionPreviewItem)`};
  ${breakpoints.smDown} {
    display: none;
  }
`;

const ContainerSommelier = styled(Container)`
  position: relative;
  z-index: 10;
  ${breakpoints.mdUp} {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const TitleSommelier = styled.h2`
  ${breakpoints.smUp} {
    font-size: 80px;
    letter-spacing: -0.24px;
    line-height: 62px;
  }
  ${breakpoints.lgUp} {
    display: inline-flex;
    align-items: flex-end;
    font-size: 115px;
    line-height: 90px;
    > span[aria-label] {
      margin-bottom: 66px;
    }
  }
  text-transform: uppercase;
  font-family: ${fonts.fontInterstateBlackCompressed};
  font-size: 60px;
  line-height: 48px;
  letter-spacing: -0.15px;
  display: inline-block;
`;

const FloatedImageSommelier = styled(Image).attrs({
  src: homeWineImage,
  alt: "Wine pairings",
})`
  position: absolute;
  max-width: 100%;
  top: 0;
  left: 15%;
  z-index: -1;
  ${breakpoints.lgDown} {
    left: 0;
    max-width: 380px;
  }
  ${breakpoints.mdDown} {
    max-width: 320px;
  }
  ${breakpoints.smDown} {
    top: 20%;
  }
  ${breakpoints.xsDown} {
    max-width: 280px;
  }
`;

const FloatedImageCupSommelier = styled(Image).attrs({
  src: homeCupImage,
  alt: "Glass of wine and bits of food.",
})`
  position: absolute;
  max-width: 600px;
  bottom: -179px;
  right: 0;
  z-index: 1;
  ${breakpoints.lgDown} {
    max-width: 580px;
    bottom: -173px;
  }
  ${breakpoints.mdDown} {
    max-width: 400px;
    bottom: -119px;
  }
  ${breakpoints.smDown} {
    bottom: -119px;
    max-width: 400px;
  }
  ${breakpoints.xsDown} {
    bottom: -89px;
    max-width: 300px;
  }
`;

const StyledSectionOverflow = styled(SectionBlue)`
  overflow: hidden;
  position: relative;
  padding-bottom: 0;
  ${breakpoints.smDown} {
    padding-bottom: 0;
  }
`;

const Hero = styled.div`
  background: ${(props) =>
    props.isWebpSupported
      ? `url(${heroBgMobileAlt}) no-repeat 0% 60% / cover`
      : `url(${heroBgMobile}) no-repeat 0% 60% / cover`};
  padding: 75px 0 400px 0;
  position: relative;
  z-index: 10;
  overflow: hidden;
  ${breakpoints.smUp} {
    background: ${(props) =>
      props.isWebpSupported
        ? `url(${heroBgDesktopAlt}) no-repeat 0% 60% / cover`
        : `url(${heroBgDesktop}) no-repeat 0% 60% / cover`};
  }
  ${breakpoints.mdUp} {
    padding: 260px 0;
    margin-top: -${header.desktopHeight};
  }
  ${breakpoints.lgUp} {
    padding: 228px 0;
    min-height: 810px;
    background: ${(props) =>
      props.isWebpSupported
        ? `url(${heroBgDesktopAlt}) no-repeat 0% 80% / cover`
        : `url(${heroBgDesktop}) no-repeat 0% 80% / cover`};
  }
  @media (min-width: 1480px) {
    min-height: 890px;
  }
  @media (min-width: 1600px) {
    min-height: 950px;
  }
  @media (min-width: 1720px) {
    min-height: 1050px;
  }
  @media (min-width: 1920px) {
    min-height: 1150px;
    padding: 360px 0;
  }
  @media (min-width: 2150px) {
    min-height: 1350px;
    padding: 460px 0;
  }
  @media (min-width: 2500px) {
    min-height: 1650px;
    padding: 560px 0;
  }
  ${breakpoints.xsDown} {
    background-position: 50% 60%;
  }
`;

const HeroTitleHolder = styled.div`
  ${breakpoints.xsDown} {
    max-width: 92%;
    margin: 0 auto;
  }
  ${breakpoints.mdUp} {
    margin-left: 25%;
  }
`;

const HeroTitle = styled.h1`
  font-family: ${fonts.fontInterstateBlackCompressed};
  font-size: 73px;
  line-height: 73px;
  margin-bottom: 30px;
  ${breakpoints.lgDown} {
    font-size: 70px;
    br {
      display: none;
    }
  }
`;

const SectionPress = styled(Section)`
  ${breakpoints.smDown} {
    padding: 46px 0 60px;
  }
`;

const PressQuotesContainer = styled(AlignCenter)`
  margin-bottom: 55px;
  ${breakpoints.smDown} {
    margin-bottom: 46px;
  }
`;

const SectionHow = styled(Section)`
  position: relative;
  padding-top: 80px;
  ${breakpoints.xlUp} {
    max-width: ${BREAKPOINT.XL}px;
    margin-left: auto;
    margin-right: auto;
  }
  ${breakpoints.smDown} {
    padding-top: 46px;
    padding-bottom: 10px;
  }
`;

const FloatedImageCouple = styled(Image).attrs({
  src: floatedImageCouple,
  alt: "Tasting wine and food",
})`
  position: absolute;
  top: -250px;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 630px;
  ${breakpoints.xlDown} {
    max-width: 500px;
  }
  ${breakpoints.lgDown} {
    max-width: 400px;
  }
  ${breakpoints.smDown} {
    max-width: 320px;
  }
`;

const FineTuneHolder = styled.div`
  ${breakpoints.smUp} {
    max-width: 1180px;
    margin: 60px auto 0 auto;
  }
  display: flex;
  flex-wrap: wrap;
  counter-reset: fine-tune-counter;
  ${breakpoints.smDown} {
    flex-direction: column;
  }
`;

const FineTuneBody = styled.div`
  ${breakpoints.smUp} {
    min-width: 170px;
  }
  ${breakpoints.mdUp} {
    min-width: 200px;
  }
  ${breakpoints.lgDown} {
    font-size: 14px;
    line-height: 20px;
  }
  ${breakpoints.lgUp} {
    font-size: 18px;
    line-height: 27px;
    min-width: 260px;
    max-width: 300px;
  }
  flex: 1;
  display: flex;
  flex-direction: column;
  &:before {
    content: "" counter(fine-tune-counter);
    font-family: ${fonts.fontInterstateBlackCompressed};
    font-size: 130px;
    letter-spacing: -0.24px;
    line-height: 100px;
    margin-bottom: 20px;
  }
  a {
    text-decoration: underline;
    &:hover {
      color: ${colors.coral};
    }
  }
`;

const FineTuneImageHolder = styled.div`
  ${breakpoints.smUp} {
    padding: 0 20px;
  }
`;

const FineTuneImage = styled(Image)`
  ${breakpoints.mdDown} {
    max-width: 96px;
  }
  ${breakpoints.smDown} {
    position: absolute;
  }
`;

const FineTune = styled.div`
  ${breakpoints.smUp} {
    width: 50%;
    flex-basis: 50%;
    padding: 0 15px;
    &:first-child {
      flex-direction: row-reverse;
    }
    &:nth-child(2) {
      margin-top: 290px;
    }
    &:nth-child(3) {
      margin-top: -100px;
      ${breakpoints.lgDown} {
        margin-top: -40px;
      }
    }
  }
  ${breakpoints.mdUp} {
    padding: 0 50px;
  }
  counter-increment: fine-tune-counter;
  display: flex;
  position: relative;
  &:first-child {
    ${FineTuneBody} {
      ${breakpoints.smUp} {
        padding-top: 20px;
      }
      &:before {
        color: ${colors.coral};
      }
    }
    ${FineTuneImage} {
      ${breakpoints.smDown} {
        max-width: 90px;
        right: 20%;
        left: auto;
        top: -15px;
      }
      @media (max-width: 359px) {
        max-width: 70px;
        right: 30%;
        top: 0;
      }
    }
  }
  &:nth-child(2) {
    ${FineTuneBody} {
      ${breakpoints.lgUp} {
        max-width: 275px;
      }
      ${breakpoints.lgDown} {
        max-width: 280px;
      }
      &:before {
        color: ${colors.pinkSalmon};
      }
    }
    ${FineTuneImage} {
      ${breakpoints.smUp} {
        max-width: 231px;
        top: -86px;
        right: -16px;
        position: absolute;
      }
      ${breakpoints.mdUp} {
        max-width: 290px;
        top: -152px;
        right: 8px;
      }
      ${breakpoints.lgUp} {
        max-width: 320px;
        top: -179px;
        right: 97px;
      }
      ${breakpoints.smDown} {
        max-width: 197px;
        right: 5px;
        left: auto;
        top: 7px;
      }
      @media (max-width: 359px) {
        max-width: 154px;
        right: -8px;
      }
    }
  }
  &:nth-child(3) {
    ${FineTuneBody} {
      &:before {
        color: ${colors.blue};
      }
    }
    ${FineTuneImage} {
      ${breakpoints.smUp} {
        max-width: 202px;
        position: absolute;
        top: 100px;
        right: -170px;
      }
      ${breakpoints.mdUp} {
        max-width: 243px;
      }
      ${breakpoints.lgUp} {
        max-width: 285px;
        top: 90px;
        right: -70px;
      }
      ${breakpoints.smDown} {
        max-width: 184px;
        right: 12px;
        left: auto;
        top: 7px;
      }
      @media (max-width: 359px) {
        max-width: 154px;
        right: 4px;
      }
    }
  }
  ${breakpoints.smDown} {
    padding: 70px 35px 0px 35px;
    margin-bottom: 40px;
  }
`;

export default Home;
